export const NOTIFICATION_EVENTS = '/admin/app/notification-events';
export const NOTIFICATION_TEMPLATES = '/admin/app/notification-templates';
//Dashboard
export const DEFAULT_INFO = 'default-info';
export const DEFAULT_ACADEMIC_INFO = 'default-academic-info';
export const DEFAULT_DASHBOARD_INFO = 'dashboard-info';
export const AVAILABLE_COURSE_LIST = 'available-course-list';
export const STUDENT_OVERVIEW = 'student-overview';
export const DOUGHNUT_DATA = 'get-doughnut-data';
export const INSTRUCTOR_STUDENT_OVERVIEW = 'instructor-student-overview';
export const GET_REQUEST_OVERVIEW = 'get-request-overview';
export const BUSINESS_OVERVIEW = 'business-overview';
export const CONTACT_OVERVIEW = 'contact-overview';
export const TEAM_OVERVIEW = 'team-overview';
export const DOCTORS_LIST = 'doctors-list';
export const UPCOMING_APPOINTMENTS = 'upcoming-appointments';
export const DEFAULT_HOSPITAL_INFO = 'default-hospital-info';
export const HOSPITAL_ACTIVITIES = 'hospital-activities';
export const PATIENT_STATISTICS = 'patient-statistics';
export const HRM_BASIC_DATA = 'hrm-basic-data';
export const PROJECT_OVERVIEW = 'project-overview';
export const YEARLY_HOLIDAY = 'yearly-holiday';
export const EMPLOYEE_OVERVIEW = 'employee-overview';

//Profile
export const MY_PROFILE = '/my-profile';
export const LOGOUT = '/admin/log-out';
export const All_NOTIFICATION = '/all-notifications';

//app setting view
export const APP_SETTINGS = '/app-setting';
export const GENERAL_SETTINGS = 'general-settings';

//reCAPTCHA
export const GET_RECAPTCHA_SETTINGS = '/get-re-captcha-setting';
//Sms settings
export const  GET_SMS_SETTINGS_INFO = 'get-sms-setting-info';
//Email settings
export const GET_EMAIL_SETTINGS = '/admin/app/settings/delivery-settings';

//Applications
export const APPLICATIONS = '/applications';
export const DATATABLE_SEARCH_SELECT = '/datatable/name';
export const APP_DETAILS = '/appDetails';
export const PURCHASE_KEY = '/purchase-keys';
export const RELEASE = '/application-release';
export const RELEASE_STATUS = '/release-status';
export const DOWNLOAD_ZIP = '/download';
export const BLOCK = '/application-block';

//roles and users
export const ROLES ='admin/auth/roles';
export const USERS ='admin/auth/users';
export const USERS_LIST ='user-list';
export const ALL_USERS ='all-users';
export const GET_USERS ='get/users';
export const GET_STATUSES ='admin/app/statuses';
export const INVITE_USER ='/admin/auth/users/invite-user';
export const DETACH_ROLES ='admin/auth/users/detach-roles/';
export const UPDATE_USER_NAME ='/update-user-name/';

//Notification
export const NOTIFICATIONS = '/admin/user/notifications';
export const AUTHENTICATE_USER = '/admin/authenticate-user';
export const LOGIN_USER = '/login-user';

// Language
export const LANGUAGE = 'languages';
export const BASIC_SETTINGS = '/get-basic-setting-data';

// Social links
export const CHANGE_SOCIAL_LINKS = '/change-social-link';
