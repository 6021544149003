<template>
    <div class="content">
<!--        <div class="d-flex justify-content-end mb-4">-->
<!--            <button type="button"-->
<!--                    class="btn btn-primary btn-with-shadow"-->
<!--                    data-toggle="modal"-->
<!--                    @click="openAddEditModal">-->
<!--                {{ $t('add') }}-->
<!--            </button>-->
<!--        </div>-->
        <app-table :id="tableId" :options="options" @action="getListAction"/>

        <purchase-add-edit-modal v-if="isAddEditModalActive"
                                 :table-id="tableId"
                                 :app-id="props.app_id"
                                 :selected-url="selectedUrl"
                                 @close-modal="closeAddEditModal"/>

        <app-confirmation-modal v-if="deleteConfirmationModalActive"
                                modal-id="demo-delete"
                                @confirmed="confirmed"
                                @cancelled="cancelled"/>
    </div>
</template>

<script>
    import CoreLibrary from "../../../../../core/helpers/CoreLibrary.js";
    import * as actions from "../../../../config/ApiUrl";
    import {PurchaseKeyTableHelpers} from "../mixins/PurchaseKeyTableHelpers";
    export default {
        extends: CoreLibrary,
        name: "applicationPurchaseKeys",
        mixins: [PurchaseKeyTableHelpers],

        props: ['props', 'id'],
        data() {
            return {
                isAddEditModalActive: false,
                deleteConfirmationModalActive: false,
                selectedUrl: '',
                tableId: 'application-purchase-key-table',
                rowData: {},
                options: {
                    url: `/purchase/${this.props.app_id}`,
                    name: this.$t('purchase_keys'),
                    tableShadow: false,
                    tablePaddingClass:'p-0',

                    showHeader: true,
                    columns: [],
                    actions: [
                        {
                            title: this.$t('edit'),
                            type: 'none',
                            component: '',
                            modalId: '',

                        },
                        {
                            title: this.$t('delete'),
                            type: 'none',
                            component: 'app-confirmation-modal',
                            modalId: 'demo-delete',
                        },
                    ],
                    showFilter: false,
                    showSearch: false,
                    paginationType: "pagination",
                    responsive: true,
                    rowLimit: 10,
                    showAction: true,
                    orderBy: 'desc',
                    actionType: "dropdown",
                }
            }
        },
        created(){
            this.options.columns = [...this.tableColumns, this.actionObj];
        },
        mounted() {
            this.$hub.$on('headerButtonClicked-' + this.id, () => {
                this.isAddEditModalActive = true;
            })
        },
        beforeDestroy() {
            this.$hub.$off('headerButtonClicked-' + this.id);
        },
        methods: {
            /**
             * for open add edit modal
             */
            openAddEditModal() {
                this.isAddEditModalActive = true;
            },

            /**
             * for close add edit modal
             */
            closeAddEditModal() {
                $("#purchase-key-add-edit-modal").modal('hide');
                this.isAddEditModalActive = false;
                this.reSetData();
            },

            /**
             * $emit form datatable action
             */
            getListAction(rowData, actionObj, active) {

                this.rowData = rowData;

                if (actionObj.title == 'Delete') {

                    this.openDeleteModal();
                } else if (actionObj.title == this.$t('edit')) {
                    this.selectedUrl = `${actions.PURCHASE_KEY}/${rowData.id}`;
                    this.openAddEditModal();
                }
            },

            /**
             * for open confirmation modal
             */
            openDeleteModal() {
                this.deleteConfirmationModalActive = true;
            },

            /**
             * confirmed $emit form confirmation modal
             */
            confirmed() {
                let url = `${actions.PURCHASE_KEY}/${this.rowData.id}`;

                this.axiosDelete(url)
                    .then(response => {

                        this.$toastr.s(response.data.message);
                        this.$hub.$emit('reload-' + this.tableId);
                    }).catch(({error}) => {

                    //trigger after error
                }).finally(() => {

                    this.cancelled();
                });
            },

            /**
             * cancelled $emit form confirmation modal
             */
            cancelled() {
                this.deleteConfirmationModalActive = false;
                this.reSetData();
            },

            reSetData() {
                this.rowData = {};
                this.selectedUrl = '';
            }
        }
    }
</script>
