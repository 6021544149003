import {axiosGet} from '../../../helpers/AxiosHelper';
import DateFunction from "../../../../core/helpers/date/DateFunction";
import * as actions from '../../../config/ApiUrl';
import * as dateTimeFunc from '../../../helpers/DateTimeHelper';

export default {

	state: {
		loggedInUser:{},
		profile_picture: '',
        notificationList : [],
        socialLinks: [],
        users: [],
	},

    getters: {
        notificationList : state => state.notificationList,
        getUsers: state => state.users,
    },
	mutations: {

        SET_USERS(state, data) {
            state.users = data
        },

	    GET_USER(state,obj){
            const {rootState,user} = obj;
            state.loggedInUser = user;
            if (user.profile) {
                state.loggedInUser.date_of_birth = user.profile.date_of_birth ? new Date(user.profile.date_of_birth) : null;
                state.loggedInUser.gender = user.profile?.gender;
                state.loggedInUser.contact = user.profile.contact ? user.profile.contact : '';
                state.loggedInUser.address = user.profile?.address;
            }
            state.loggedInUser.created_at = DateFunction.getDateFormat(user.created_at, rootState.settings.dateFormat);
            state.profile_picture = user.profile_picture?.path;
            state.loggedInUser.img = user.profile_picture?.path;

            state.socialLinks = user?.social_links.filter((item, index) =>{
                    item.link = item.pivot.link;
                    item.edit = false;
                    return item;
            });
        },

        GET_NOTIFICATIONS(state, obj){
	        const {rootState,notifications} = obj,
                timeFormat = rootState.settings.timeFormat,
                dateFormat = rootState.settings.dateFormat,
                {selectedLanguage} = rootState.settings;

            state.notificationList = notifications.map(item => {
                const {data, notifier, created_at, read_at} = item,
                    profile_picture = notifier?.profile_picture;

                return {
                    img: !_.isEmpty(profile_picture) ? profile_picture.path : '',
                    name: data.name,
                    title: data.message,
                    description: '',
                    time: dateTimeFunc.getTimeFromDateTime(created_at, timeFormat),
                    date: dateTimeFunc.getDateFromNow(created_at, dateFormat),
                    status: read_at ? selectedLanguage['old'] : selectedLanguage['new'],
                    url: ''
                }
            })
        },
	},

	actions: {
		getUser({commit, rootState}){
		    
			axiosGet(actions.LOGIN_USER).then( res => {
				const obj = {
					user : res.data,
					rootState
				};
				commit('GET_USER',obj);
			});
		},
        addUser({commit, state}, user){
            commit('SET_USERS', state.users.concat([user]));
        },
        getNotifications({commit, rootState}) {
		    const url = `${actions.NOTIFICATIONS}?per_page=5`;
            axiosGet(url).then( ({data}) => {
                let obj = {
                    notifications : data.data,
                    rootState
                };
                commit('GET_NOTIFICATIONS',obj);
            });
        },

        getUsers({commit, state}, payload = {}) {
            const url = payload.users ? `${actions.GET_USERS}?existing=${payload.users}` : actions.GET_USERS;
            axiosGet(url).then(({data}) => {
                commit('SET_USERS', data)
            });
        },
    }
}
