import * as actions from "../../../../config/ApiUrl";
import {BLOCK} from "../../../../config/ApiUrl";

export const BlockTableMixin = {
    data() {
        return {
            isAddEditModalActive: false,
            deleteConfirmationModalActive: false,
            selectedUrl: '',
            tableId: 'application-block-table',
            rowData: {},
            options: {
                url: `block-list/${this.props.app_id}`,
                name: this.$t('block'),
                tableShadow: false,
                tablePaddingClass: 'p-0',
                showHeader: true,
                columns: [
                    {
                        title: this.$t('app_name'),
                        type: 'object',
                        key: 'application',
                        modifier: (value) => {
                            if (value) {
                                return value.app_name
                            }
                        }
                    },
                    {
                        title: this.$t('domain_name'),
                        type: 'text',
                        key: 'domain_name',
                    },
                ],
                actions: [
                    {
                        title: this.$t('edit'),
                        type: 'none',
                        component: '',
                        modalId: 'application-block-add-edit-modal',

                    },
                    {
                        title: this.$t('delete'),
                        type: 'none',
                        component: 'app-confirmation-modal',
                        modalId: 'demo-delete',
                    },
                ],
                showFilter: false,
                showSearch: false,
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                actionType: "dropdown",
            }
        }
    },
    beforeMount() {
        this.options.columns.push({
            title: this.$t('actions'),
            type: 'action'
        })

    },
    mounted() {
        this.$hub.$on('headerButtonClicked-' + this.id, () => {
            this.isAddEditModalActive = true;
        })
    },

    beforeDestroy() {
        this.$hub.$off('headerButtonClicked-' + this.id);
    },
    methods: {
        /**
         * for open add edit modal
         */
        openAddEditModal() {
            this.isAddEditModalActive = true;
        },

        /**
         * for close add edit modal
         */
        closeAddEditModal() {
            $("#application-block-add-edit-modal").modal('hide');
            this.isAddEditModalActive = false;
            this.reSetData();
        },

        /**
         * $emit form datatable action
         */
        getListAction(rowData, actionObj, active) {

            this.rowData = rowData;

            if (actionObj.title == 'Delete') {

                this.openDeleteModal();
            } else if (actionObj.title == this.$t('edit')) {
                this.selectedUrl = `${actions.BLOCK}/${rowData.id}`;
                this.openAddEditModal();

            }
        },

        /**
         * for open confirmation modal
         */
        openDeleteModal() {
            this.deleteConfirmationModalActive = true;
        },

        /**
         * confirmed $emit form confirmation modal
         */
        confirmed() {
            let url = `${actions.BLOCK}/${this.rowData.id}`;

            this.axiosDelete(url)
                .then(response => {

                    this.$toastr.s(response.data.message);
                    this.$hub.$emit('reload-' + this.tableId);
                }).catch(({error}) => {

                //trigger after error
            }).finally(() => {

                this.cancelled();
            });
        },

        /**
         * cancelled $emit form confirmation modal
         */
        cancelled() {
            this.deleteConfirmationModalActive = false;
            this.reSetData();
        },

        reSetData() {
            this.rowData = {};
            this.selectedUrl = '';
        }
    }
}