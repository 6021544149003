<template>
    <div class="">
        <app-table :id="tableId" :options="options"/>
    </div>
</template>

<script>

    import CoreLibrary from "../../../../core/helpers/CoreLibrary.js";
    import {LogTableMixin} from "./mixins/LogTableMixin";

    export default {
        extends: CoreLibrary,
        name: "ApplicationLogs",
        mixins: [LogTableMixin],

        props: ['props'],
        data() {
            return {
                selectedUrl: '',
                tableId: 'application-logs-table',
                rowData: {},
                options: {
                    url: `/get-log-data/${this.props.app_id}`,
                    name: this.$t('logs'),
                    tableShadow: false,
                    showHeader: true,
                    tablePaddingClass:'p-0',
                    columns: [],
                    showFilter: false,
                    showSearch: false,
                    paginationType: "pagination",
                    responsive: true,
                    rowLimit: 10,
                    orderBy: 'desc',
                }
            }
        },
        created(){
            this.options.columns = [...this.tableColumns];
        },
    }
</script>
