<template>
    <div class="content-wrapper">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <app-breadcrumb :page-title="$t('application')" :directory="$t('lists')" :icon="'grid'"/>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="float-md-right mb-3 mb-sm-3 mb-md-0">
                    <button type="button"
                            class="btn btn-primary btn-with-shadow"
                            data-toggle="modal"
                            @click="openAddEditModal">
                        {{ $t('add') }}
                    </button>
                </div>
            </div>
        </div>

        <app-table :id="tableId" :options="options" @action="getListAction"/>

        <app-add-modal v-if="isAddEditModalActive"
                       :table-id="tableId"
                       :selected-url="selectedUrl"
                       @close-modal="closeAddEditModal"/>

        <app-confirmation-modal v-if="deleteConfirmationModalActive"
                                modal-id="demo-delete"
                                @confirmed="confirmed"
                                @cancelled="cancelled"/>
    </div>
</template>

<script>
    import CoreLibrary from "../../../../core/helpers/CoreLibrary.js";
    import * as actions from "../../../config/ApiUrl";
    import {TableHelpers} from "../tables/mixins/TableHelpers";

    export default {
        extends: CoreLibrary,
        name: "ApplicationLists",
        mixins: [TableHelpers],
        data() {
            return {
                isAddEditModalActive: false,
                deleteConfirmationModalActive: false,
                selectedUrl: '',
                tableId: 'application-table',
                rowData: {},
                options: {
                    url: actions.APPLICATIONS,
                    name: this.$t('application'),

                    showHeader: true,
                    columns: [],
                    actions: [
                        {
                            title: this.$t('edit'),
                            type: 'none',
                            component: 'app-add-modal',
                            modalId: 'demo-add-edit-modal',

                        },
                        {
                            title: this.$t('delete'),
                            type: 'none',
                            component: 'app-confirmation-modal',
                            modalId: 'demo-delete',
                        },
                        {
                            title: this.$t('published'),
                            type: 'none',
                            modifier: (row) => {
                                const {status} = row;
                                if (status != 'published') {
                                    return true;
                                }
                            }
                        },
                        {
                            title: this.$t('maintenance'),
                            type: 'none',
                            modifier: (row) => {
                                const {status} = row;
                                if (status == 'published') {
                                    return true;
                                }
                            }
                        }
                    ],
                    showFilter: false,
                    showSearch: false,
                    paginationType: "pagination",
                    responsive: true,
                    rowLimit: 10,
                    showAction: true,
                    orderBy: 'desc',
                    actionType: "dropdown",
                }
            }
        },
        created(){
            this.options.columns = [...this.tableColumns, this.actionObj];
        },
        methods: {
            /**
             * for open add edit modal
             */
            openAddEditModal() {
                this.isAddEditModalActive = true;
            },

            /**
             * for close add edit modal
             */
            closeAddEditModal() {
                $("#demo-add-edit-modal").modal('hide');
                this.isAddEditModalActive = false;
                this.reSetData();
            },

            /**
             * $emit form datatable action
             */
            getListAction(rowData, actionObj, active) {

                this.rowData = rowData;

                if (actionObj.title == 'Delete') {

                    this.openDeleteModal();
                } else if (actionObj.title == this.$t('edit')) {
                    this.selectedUrl = `${actions.APPLICATIONS}/${rowData.id}`;
                    this.openAddEditModal();
                } else if (actionObj.title == this.$t('published')) {
                    this.changeApplicationStatus('published',rowData);
                } else if (actionObj.title == this.$t('maintenance')) {
                    this.changeApplicationStatus('maintenance',rowData);
                }
            },

            /**
             * for open change Application status
             */
            changeApplicationStatus(value, rowData) {
                let url = `${actions.APPLICATIONS}/${rowData.id}`,
                    reqData = rowData;

                reqData.status = value;

                this.axiosPatch({
                    url: url,
                    data: reqData
                }).then(res => {
                    this.$toastr.s(res.data.message);
                    this.$hub.$emit('reload-' + this.tableId);

                }).catch(error => {
                    let {message} = error.response.data;
                    this.$toastr.e(message);
                }).finally(() => {
                    this.reSetData();
                });
            },

            /**
             * for open confirmation modal
             */
            openDeleteModal() {
                this.deleteConfirmationModalActive = true;
            },

            /**
             * confirmed $emit form confirmation modal
             */
            confirmed() {
                let url = `${actions.APPLICATIONS}/${this.rowData.id}`;

                this.axiosDelete(url)
                    .then(response => {

                        this.$toastr.s(response.data.message);
                        this.$hub.$emit('reload-' + this.tableId);
                    }).catch(({error}) => {

                    //trigger after error
                }).finally(() => {

                    this.cancelled();
                });
            },

            /**
             * cancelled $emit form confirmation modal
             */
            cancelled() {
                this.deleteConfirmationModalActive = false;
                this.reSetData();
            },

            reSetData() {
                this.rowData = {};
                this.selectedUrl = '';
            }
        }
    }
</script>
