<template>
    <dashboard-modal
        :modal-id="modalId"
        :title="modalTitle"
        :preloader="preloader"
        :modal-scroll="false"
        @submit="submit"
        @close-modal="closeModal">

        <template slot="body">
            <app-overlay-loader v-if="preloader"/>
            <form ref="form" :data-url='selectedUrl ? `application-release/${inputs.id}` : `application-release`'
                  :class="{'loading-opacity': preloader}">
                <div class="form-group row align-items-center">
                    <label for="version" class="col-sm-3 mb-0">{{ $t('version') }}</label>
                    <app-input
                        id="version"
                        class="col-sm-9"
                        type="text"
                        v-model="inputs.version"
                        :placeholder="$t('version')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'version')"
                    />

                </div>
                <div class="form-group row align-items-center">
                    <label for="inputs_upload_file" class="col-sm-3 mb-0">{{ $t('upload_file') }}</label>
                    <app-input
                        id="inputs_upload_file"
                        class="col-sm-9"
                        type="custom-file-upload"
                        v-model="inputs.zip_file_reference"
                        :label="$t('zip_only')"
                        :error-message="$errorMessage(errors, 'zip_file_reference')"
                        :required="true"/>
                </div>
                <div class="form-group row align-items-center">
                    <label for="release_date" class="col-sm-3 mb-0">{{ $t('release_date') }}</label>
                    <app-input
                        class="col-sm-9"
                        id="release_date"
                        :min-date="inputs.release_date"
                        type="date"
                        v-model="inputs.release_date"
                        :error-message="$errorMessage(errors, 'release_date')"
                        :required="true"
                    />
                </div>
            </form>
        </template>

    </dashboard-modal>
</template>

<script>
import {FormMixin} from "../../../../../core/mixins/form/FormMixin";
import {ModalMixin} from "../../../../Mixins/ModalMixin";
import AppLibrary from "../../../../helpers/AppLibrary";
import CoreHelpers from "../../../../../core/helpers/CoreLibrary"
import {formDataAssigner} from "../../../../helpers/helpers";
import DateFunction from "../../../../../core/helpers/date/DateFunction";
import ErrorHandlerMixin from "../../../../Mixins/ErrorHandlerMixin";

export default {
    name: "AddEditRelease",
    extends: AppLibrary, CoreHelpers,
    mixins: [ModalMixin, FormMixin, ErrorHandlerMixin],
    props: {
        tableId: String,
        appId: {},
    },

    data() {
        return {
            preloader: false,
            inputs: {
                application_id: this.appId,
                release_date: new Date(),
                zip_file_reference: '',
            },
            modalId: 'application-release-add-edit-modal',
            modalTitle: this.$t('add_new_release'),
        }
    },

    created() {
        if (this.selectedUrl) {
            this.modalTitle = this.$t('edit');
            this.preloader = true;
        }
    },

    methods: {

        submit() {
            this.errorHandle = true;
            if (this.inputs.release_date)
                this.inputs.release_date = DateFunction.getDateFormatForBackend(this.inputs.release_date);

            let formData = formDataAssigner(new FormData, this.inputs),
                url = this.selectedUrl ? `application-release-update/${this.inputs.id}` : `application-release`;

            this.submitFromFixin('post', url, formData);
        },

        afterJsValidationFail(){
            this.inputs.release_date = new Date(this.inputs.release_date);
        },

        afterSuccess(response) {
            this.$toastr.s(response.data.message);
            this.$hub.$emit('reload-' + this.tableId);
            this.closeModal();
        },

        afterSuccessFromGetEditData(response) {
            this.inputs.id = response.data.id;
            this.inputs.version = response.data.version;
            this.inputs.release_date = new Date(response.data.release_date);
            this.preloader = false;
        },
    },
}
</script>
