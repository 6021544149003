<template>
    <div class="">
        <app-table :id="tableId" :options="options"/>
    </div>
</template>

<script>
import {PurchaseSummaryMixin} from "../mixins/PurchaseSummaryMixin";

export default {
    name: "PurchaseSummary",
    mixins: [PurchaseSummaryMixin],
    props: ['props'],
    data() {
        return {
            selectedUrl: '',
            tableId: 'purchase-summary-table',
            rowData: {},
            options: {
                url: `/purchase-summary/${this.props.app_id}`,
                name: this.$t('logs'),
                tableShadow: false,
                showHeader: true,
                tablePaddingClass:'p-0',
                columns: [],
                showFilter: false,
                showSearch: false,
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                orderBy: 'desc',
            }
        }
    },
    created(){
        this.options.columns = [...this.tableColumns];
    },
}
</script>

