<template>
    <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8">
            <div class="card card-with-shadow border-0 my-primary">
                <div class="card-header p-primary bg-transparent">
                    <h5 class="card-title m-0">{{ $t('debug') }}</h5>
                </div>
                <div class="card-body position-relative">
                    <app-overlay-loader v-if="preloader"/>
                    <form ref="form" data-url="/verify-envato/" class="mb-0"
                          :class="{'loading-opacity': preloader}">
                        <div class="form-group row align-items-center">
                            <label for="text_input" class="col-sm-2 mb-sm-0">
                                {{ $t('purchase_key') }}
                            </label>
                            <app-input id="text_input"
                                       class="col-sm-10"
                                       type="text"
                                       v-model="inputs.purchase_key"
                                       :placeholder="$t('purchase_key')"/>

                        </div>

                        <div class="mt-5 action-buttons">
                            <button type="submit" class="btn btn-primary mr-2" @click.prevent="submit">
                                {{ $t('submit') }}
                            </button>
                            <button type="submit" class="btn btn-secondary" @click.prevent="clear">
                                {{ $t('clear') }}
                            </button>
                        </div>
                    </form>

                </div>
                <template v-if="noteActive">
                    <div class="px-primary">
                        <app-note
                            class="mb-primary"
                            :title="$t('note')"
                            :show-title="false"
                            content-type="html"
                            :notes="responseNote"/>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>

<script>

import {axiosGet} from "../../../../helpers/AxiosHelper";
import {FormMixin} from "../../../../../core/mixins/form/FormMixin";

export default {
    name: "Debug",
    mixins: [FormMixin],
    data() {
        return {
            preloader: false,
            inputs: {},
            noteActive:false,
            info:{
                buyer:'',
                item_name:'',
                license:'',
                support_until:''
            },
            responseNote: '',
        }
    },
    methods: {
        submit() {
            this.preloader = true;
            axiosGet('debug-v2/' + this.inputs.purchase_key + "/" + true)
                .then((response) => {

                    if (response.data.status){
                        this.info.buyer = response.data.data.buyer;
                        this.info.item_name = response.data.data.item.name;
                        this.info.license = response.data.data.license;
                        this.info.support_until = response.data.data.supported_until;
                        const formatType = "YYYY-MM-DD"
                        this.responseNote = '<div class="mb-3 font-weight-bold">\n' +
                            '        <p class="mb-3">\n' +
                            '            <span class="font-weight-bold">Purchased By: </span> \n' +this.info.buyer+
                            '        </p>\n' +
                            '         <p class="mb-3">\n' +
                            '            <span class="font-weight-bold">Item Name: </span> \n' +this.info.item_name+
                            '        </p>\n' +
                            '        <p class="mb-3">\n' +
                            '            <span class="font-weight-bold">License: </span> \n' +this.info.license+
                            '        </p>\n' +
                            '        <p class="mb-3">\n' +
                            '            <span class="font-weight-bold">Supported until: </span>\n' +moment(this.info.support_until).format(formatType)+
                            '        </p>\n' +
                            '    </div>';
                    }else {
                        this.responseNote = '<div class="mb-3 font-weight-bold">\n' +
                            '<p class="mb-3">\n' +response.data.message+ '</p></div>';
                    }

                    this.noteActive = true;

                })
                .catch(({response}) => {

                    this.responseNote = '<div class="mb-3 font-weight-bold">\n' +
                        '<p class="mb-3">\n' +response.data.message+ '</p></div>';
                    this.noteActive = true;
                })
                .finally(() => {
                    this.preloader = false;
                });
        },
        clear() {
            this.inputs = {};
            this.info = {};
            this.noteActive = false;
        },
    }
}
</script>
