<template>
    <dashboard-modal
        :modal-id="modalId"
        :title="modalTitle"
        :preloader="preloader"
        :modal-scroll="false"
        @submit="submit"
        @close-modal="closeModal">

        <template slot="body">
            <app-overlay-loader v-if="preloader"/>
            <form ref="form"
                  :data-url='selectedUrl ? `application-block/${formData.id}/show/` : `application-block`'
                  :class="{'loading-opacity': preloader}">

                <div class="form-group row align-items-center">
                    <label for="domain_name" class="col-sm-3 mb-0">{{ $t('domain_name') }}</label>
                    <app-input
                        class="col-sm-9"
                        id="domain_name"
                        type="text"
                        v-model="formData.domain_name"
                        :required="true"
                        :placeholder="$t('domain_name')"
                    />
                </div>
            </form>
        </template>
    </dashboard-modal>
</template>

<script>
import {FormMixin} from "../../../../../core/mixins/form/FormMixin";
import {ModalMixin} from "../../../../Mixins/ModalMixin";
import AppLibrary from "../../../../helpers/AppLibrary";
import CoreHelpers from "../../../../../core/helpers/CoreLibrary"
import ErrorHandlerMixin from "../../../../Mixins/ErrorHandlerMixin";

export default {
    name: "AddEditBlock",
    extends: AppLibrary, CoreHelpers,
    mixins: [FormMixin, ModalMixin, ErrorHandlerMixin],
    props: {
        tableId: String,
        appId: {},
    },
    data() {
        return {
            preloader: false,
            formData: {
                application_id: this.appId,
                domain_name: '',
            },
            modalId: 'application-block-add-edit-modal',
            modalTitle: this.$t('add_blocked_domain'),
        }
    },
    created() {
        if (this.selectedUrl) {
            this.modalTitle = this.$t('edit');
            this.preloader = true;
        }
    },
    methods: {
        submit() {
            this.errorHandle = true;
            let url = this.selectedUrl ? `application-block-update/${this.formData.id}` : `application-block`;
            this.submitFromFixin('post', url, this.formData);
        },
        afterSuccess(response) {
            this.$toastr.s(response.data.message);
            this.$hub.$emit('reload-' + this.tableId);
            this.closeModal();
        },

        afterSuccessFromGetEditData(response) {
            this.formData.id = response.data.id;
            this.formData.domain_name = response.data.domain_name;
            this.preloader = false;
        },
    },
}
</script>
