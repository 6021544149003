<template>

    <dashboard-modal :modal-id="modalId"
                     :title="modalTitle"
                     :preloader="preloader"
                     @submit="submit"
                     @close-modal="closeModal">

        <template slot="body">

            <app-overlay-loader v-if="preloader"/>

            <form ref="form" :data-url='selectedUrl ? `applications/${inputs.id}` : `applications`'
                  :class="{'loading-opacity': preloader}">

                <div class="form-group row align-items-center">
                    <label for="inputs_app_id" class="col-sm-3 mb-0">{{ $t('app_name') }}</label>
                    <app-input
                        class="col-sm-9"
                        type="text"
                        v-model="inputs.app_name"
                        :placeholder="$t('app_name')"
                        :required="true"/>

                </div>

                <div class="form-group row align-items-center">
                    <label for="inputs_app_id" class="col-sm-3 mb-0">{{ $t('app_id') }}</label>
                    <app-input
                        class="col-sm-9"
                        type="text"
                        v-model="inputs.app_id"
                        :placeholder="$t('app_id')"
                        :required="true"/>
                </div>

                <div class="form-group row align-items-center">
                    <label for="code_canyon_item_id" class="col-sm-3 mb-0">{{ $t('code_canyon_item_id') }}</label>
                    <app-input
                        class="col-sm-9"
                        id="code_canyon_item_id"
                        type="text"
                        v-model="inputs.code_canyon_item_id"
                        :placeholder="$t('code_canyon_item_id')"
                    />
                </div>

            </form>

        </template>

    </dashboard-modal>

</template>

<script>
import {FormMixin} from '../../../../core/mixins/form/FormMixin.js';
import {ModalMixin} from "../../../Mixins/ModalMixin";

export default {
    name: "AddModal",
    mixins: [FormMixin, ModalMixin],
    props: {
        tableId: String
    },
    data() {
        return {
            preloader: false,
            inputs: {
                code_canyon_item_id: ''
            },
            modalId: 'demo-add-edit-modal',
            modalTitle: this.$t('add'),
        }
    },
    created() {
        if (this.selectedUrl) {
            this.modalTitle = this.$t('edit');
            this.preloader = true;
        }
    },
    methods: {
        submit() {
            this.save(this.inputs);
        },
        afterSuccess(response) {
            this.$toastr.s(response.data.message);
            this.$hub.$emit('reload-' + this.tableId);
        },

        afterSuccessFromGetEditData(response) {
            this.inputs = response.data;
            this.preloader = false;
        },
    },
}
</script>
