<template>
    <dashboard-modal :modal-id="modalId"
                     :title="modalTitle"
                     :preloader="preloader"
                     @submit="submit"
                     @close-modal="closeModal">

        <template slot="body">
            <app-overlay-loader v-if="preloader"/>
            <form ref="form" :data-url='selectedUrl ? `purchase-keys/${inputs.id}` : `purchase-keys`'
                  :class="{'loading-opacity': preloader}">
                <div class="form-group row align-items-center">
                    <label for="inputs_purchase_key" class="col-sm-3 mb-0">{{ $t('purchase_key') }}</label>
                    <app-input class="col-sm-9"
                               type="text"
                               v-model="inputs.purchase_key"
                               :placeholder="$t('purchase_key')"
                               :required="true"/>

                </div>
                <div class="form-group row align-items-center">
                    <label for="inputs_domain_name" class="col-sm-3 mb-0">{{ $t('domain_name') }}</label>
                    <app-input class="col-sm-9"
                               type="text"
                               v-model="inputs.domain_name"
                               :placeholder="$t('app_domain_name')"
                               :required="true"/>
                </div>
            </form>
        </template>

    </dashboard-modal>
</template>

<script>
    import {FormMixin} from "../../../../../core/mixins/form/FormMixin";
    import {ModalMixin} from "../../../../Mixins/ModalMixin";

    export default {
        name: "AddEditPurchaseKeys",
        mixins: [FormMixin,ModalMixin],
        props: {
            tableId: String,
            appId: {},
        },
        data() {
            return {
                preloader: false,
                inputs: {
                    application_id : this.appId,
                },
                modalId: 'purchase-key-add-edit-modal',
                modalTitle: this.$t('add'),
            }
        },
        created () {
            if (this.selectedUrl) {
                this.modalTitle = this.$t('edit');
                this.preloader = true;
            }
        },
        methods: {
            submit() {
                this.save(this.inputs);
            },
            afterSuccess(response) {
                this.$toastr.s(response.data.message);
                this.$hub.$emit('reload-' + this.tableId);
            },

            afterSuccessFromGetEditData(response) {
                this.inputs = response.data;
                this.preloader = false;
            },
        },
    }
</script>