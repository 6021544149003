export const PurchaseKeyTableHelpers = {
    data() {
        return {
            tableColumns : [
                {
                    title: this.$t('purchase_key'),
                    type: 'text',
                    key: 'purchase_key',

                },
                {
                    title: this.$t('domain_name'),
                    type: 'text',
                    key: 'domain_name',

                },
            ],
            actionObj : {
                title: this.$t('action'),
                type: 'action',
            }
        }
    },
}