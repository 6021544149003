import Vue from 'vue';
window.moment = require('moment');
//vue-tel-input
import { VueTelInput } from 'vue-tel-input';
//helper app
import './components/helpers/helperApp';

/**
 * all component of marketplace
 */
Vue.component('personal-information', require('./components/views/user/PersonalInformation').default);
Vue.component('password-change',  require('./components/views/user/PasswordChange').default);
Vue.component('social-links',  require('./components/views/user/SocialLinks').default);
Vue.component('login',  require('./components/views/auth/Login').default);
Vue.component('password-reset',  require('./components/views/auth/PasswordReset').default);
Vue.component('reset-password',  require('./components/views/auth/ResetPassword').default);
Vue.component('registration',  require('./components/views/auth/Registration').default);
Vue.component('my-profile',  require('./components/views/user/Profile').default);
Vue.component('dashboard-default',  require('./components/views/dashboard/Default').default);

// Settings
Vue.component('app-setting',  require('./components/views/settings/app-settings/Index').default);
Vue.component('general-setting',  require('./components/views/settings/app-settings/GeneralSetting').default);
Vue.component('email-setting',  require('./components/views/settings/app-settings/EmailSetting').default);
Vue.component('notification-settings', require('./components/views/settings/app-settings/notification/settings/Index').default);
Vue.component('database-template', require('./components/views/settings/app-settings/notification/template/DatabaseTemplate').default);
Vue.component('mail-template', require('./components/views/settings/app-settings/notification/template/MailTemplate').default);

// Application
Vue.component('app-application',  require('./components/views/applications/Index').default);
Vue.component('app-add-modal',  require('./components/views/applications/AddModal').default);
Vue.component('app-details',  require('./components/views/applications/ApplicationDetails').default);

// Application Release
Vue.component('application-release',  require('./components/views/applications/release/ApplicationRelease').default);
Vue.component('application-release-add-edit-modal',  require('./components/views/applications/release/AddEditRelease').default);

//Application purchase
Vue.component('application-purchase-keys',  require('./components/views/applications/purchase/ApplicationPurchaseKeys').default);
Vue.component('purchase-add-edit-modal',  require('./components/views/applications/purchase/AddEditPurchaseKeys').default);
Vue.component('application-logs',  require('./components/views/applications/ApplicationLogs').default);

//Application block
Vue.component('application-block',  require('./components/views/applications/blocked/ApplicationBlock').default);
Vue.component('application-block-add-edit-modal',  require('./components/views/applications/blocked/AddEditBlock').default);


//Application Debug
Vue.component('debug',  require('./components/views/applications/debug/Debug').default);

// User and Roles
Vue.component('user-roles',  require('./components/views/user-and-roles/Index').default);
Vue.component('group-of-users',  require('./components/views/user-and-roles/roles/GroupOfUsers').default);


//overlay loader
Vue.component('app-overlay-loader', require('../core/components/preloders/OverlayLoader').default);

// Layouts
Vue.component('dashboard-navbar', require('./components/views/layouts/navbar/Index').default);
Vue.component('dashboard-sidebar', require('./components/views/layouts/sidebar/Index').default);

// notifications
Vue.component('all-notification', require('./components/views/notification/Index').default);

//Purchase Summary
Vue.component('purchase-summary', require('./components/views/applications/purchaseSummary/PurchaseSummary').default);
Vue.component('purchases-expandable-view', require('./components/views/applications/purchaseSummary/PurchasesExpandableView').default);