<template>
    <div class="position-relative">
        <div v-if="countCreatedResponse < 3" class="root-preloader position-absolute overlay-loader-wrapper">
            <div class="spinner-bounce d-flex align-items-center dashboard-preloader justify-content-center">
                <span class="bounce1 mr-1"></span>
                <span class="bounce2 mr-1"></span>
                <span class="bounce3 mr-1"></span>
                <span class="bounce4"></span>
            </div>
        </div>
        <div class="content-wrapper">
            <app-breadcrumb :page-title="$t('default')" :directory="$t('dashboard')" :icon="'pie-chart'"/>

            <app-overlay-loader v-if="!defaultData && !mainPreloader"/>
            <div class="row" v-if="defaultData">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3"
                     v-for="(item, index) in info.getDefaultDashboardInfo.topSectionData" :key="index">
                    <app-widget class="mb-primary"
                                :type="'app-widget-with-icon'"
                                :label="item.label"
                                :number="numberFormat(item.number)"
                                :icon="item.icon"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 mb-primary">
                    <app-overlay-loader v-if="!browserChart && !mainPreloader"/>
                    <div class="card card-with-shadow border-0 h-100" v-if="browserChart">
                        <div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
                            <h5 class="card-title mb-0">{{ $t('request_overview') }}</h5>
                        </div>
                        <div class="card-body">
                            <app-chart class="mb-primary"
                                       type="line-chart"
                                       :height="210"
                                       :labels="info.requestOverView.labels"
                                       :data-sets="info.requestOverView.chartData"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 mb-primary">
                    <app-overlay-loader v-if="!isActiveDoughnutData && !mainPreloader"/>
                    <div class="card card-with-shadow border-0 h-100" v-if="isActiveDoughnutData">
                        <div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
                            <h5 class="card-title mb-0">{{ $t('request_status') }}</h5>
                        </div>
                        <div class="card-body">
                            <app-chart class="mb-primary"
                                       type="dough-chart"
                                       :height="230"
                                       :labels="info.doughnutData.labels"
                                       :data-sets="info.doughnutData.dataSet"/>
                            <div class="chart-data-list">
                                <div class="row">
                                    <div class="col-12"
                                         v-for="(item, index) in info.doughnutData.chartElement" :key="index">
                                        <div class="data-group-item" :style="item.color">
                                            <span class="square" :style="item.background_color"/>
                                            {{item.key}}
                                            <span class="value">{{item.value}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {FormMixin} from '../../../../core/mixins/form/FormMixin';
    import {DashboardPreloader} from "./mixins/DashboardPreloader";
    import * as actions from '../../../config/ApiUrl';
    import {numberFormatter} from "../../../helpers/helpers";

    export default {

        mixins: [FormMixin,DashboardPreloader],
        name: "Dashboard",
        data() {
            return {
                /* Loader section and active/inactive section */
                mainPreloader: true,

                browserChart: false,
                defaultData: false,

                isActiveDoughnutData:false,
                /* end */

                info: {},
            }
        },
        created() {
            this.mainPreloader = true;
            this.getDefaultDashboardInfo();
            this.getRequestOverView();
            this.getDoughnutData();
        },
        methods: {

            getDefaultDashboardInfo() {

                let url = actions.DEFAULT_DASHBOARD_INFO;

                this.axiosGet(url).then(response => {

                    this.info.getDefaultDashboardInfo = response.data;
                    this.defaultData = true;

                }).catch(({response}) => {

                }).finally(() => {
                    this.mainPreloader = false;
                    this.countCreatedResponse++;
                });
            },


            getRequestOverView() {
                let url = actions.GET_REQUEST_OVERVIEW, reqData = {};

                this.axiosGet(
                    url
                ).then(response => {
                    this.info.requestOverView = response.data;
                    this.browserChart = true;

                }).catch(({response}) => {

                }).finally(() => {
                    this.mainPreloader = false;
                    this.countCreatedResponse++;
                });
            },


            getDoughnutData() {
                let url = actions.DOUGHNUT_DATA;

                this.axiosGet(url).then(response => {

                    this.info.doughnutData = response.data;
                    this.isActiveDoughnutData = true;

                }).catch(({response}) => {

                }).finally(() => {
                    this.mainPreloader = false;
                    this.countCreatedResponse++;
                });
            },

            numberFormat(value) {
                return numberFormatter(value);
            }

        }
    }
</script>
