<template>
    <div class="content-wrapper">
        <app-breadcrumb :page-title="pageTitle" :icon="pageIcon"/>
        <app-tab :tabs="tabs" :icon="pageIcon"/>
    </div>
</template>

<script>
    export default {
        name: "applicationDetails",
        props: {
            appName: {},
            id: String,
        },
        data() {
            return {
                pageTitle: this.appName + this.$t('details'),
                pageIcon: 'grid',
                tabs: [
                    {
                        "name": this.$t('releases'),
                        "title": this.$t('releases'),
                        "component": "application-release",
                        "props": {'app_name': this.appName, 'app_id': this.id},
                        "permission": "",
                        "headerButton": {'label': this.$t('add_release')},
                    },
                    {
                        "name": this.$t('purchase_keys'),
                        "title": this.$t('purchase_keys'),
                        "component": "application-purchase-keys",
                        "props": {'app_name': this.appName, 'app_id': this.id},
                        "permission": "",
                        "headerButton": {'label': this.$t('add')},
                    },
                    {
                        "name": this.$t('purchase_summary'),
                        "title": this.$t('purchase_summary'),
                        "component": "purchase-summary",
                        "props": {'app_name': this.appName, 'app_id': this.id},
                        "permission": "",
                    },
                    {
                        "name": this.$t('blocked_domain'),
                        "title": this.$t('blocked_domain'),
                        "component": "application-block",
                        "props": {'app_name': this.appName, 'app_id': this.id},
                        "permission": "",
                        "headerButton": {'label': this.$t('add_blocked_domain')},
                    },
                    {
                        "name": this.$t('logs'),
                        "title": this.$t('logs'),
                        "component": "application-logs",
                        "props": {'app_name': this.appName, 'app_id': this.id},
                        "permission": ""
                    },
                ]
            }
        },
    }
</script>