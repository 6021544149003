<template>
    <div class="content">
        <app-table :id="tableId" :options="options" @action="getListAction"/>

        <application-block-add-edit-modal
                v-if="isAddEditModalActive"
                :table-id="tableId"
                :app-id="props.app_id"
                :selected-url="selectedUrl"
                @close-modal="closeAddEditModal"/>

        <app-confirmation-modal v-if="deleteConfirmationModalActive"
                                modal-id="demo-delete"
                                @confirmed="confirmed"
                                @cancelled="cancelled"/>
    </div>
</template>

<script>
    import CoreLibrary from "../../../../../core/helpers/CoreLibrary.js";
    import * as actions from "../../../../config/ApiUrl";
    import {ReleaseTableHelpers} from "../mixins/ReleaseTableHelpers";
    import {BlockTableMixin} from "../mixins/BlockTableMixin";

    export default {
        extends: CoreLibrary,
        name: "ApplicationBlock",
        mixins: [BlockTableMixin],

        props: ['props', 'id'],
        data() {
            return {
            }
        },
    }
</script>
