import DateFunction from "../../../../../core/helpers/date/DateFunction";
import {getDateFormatForFrontend} from "../../../../helpers/DateTimeHelper";

export const LogTableMixin = {
    data() {
        return {
            tableColumns : [
                {
                    title: this.$t('created_at'),
                    type: 'custom-html',
                    key: 'created_at',
                    modifier: (value) => {
                        if (value) {
                            return DateFunction.getDateFormat(value, getDateFormatForFrontend(window.settings.date_format));
                        }
                    }

                },
                {
                    title: this.$t('buyer'),
                    type: 'text',
                    key: 'buyer',

                },
                {
                    title: this.$t('purchase_key'),
                    type: 'text',
                    key: 'purchase_key',

                },
                {
                    title: this.$t('domain_name'),
                    type: 'text',
                    key: 'domain_name',

                },
                {
                    title: this.$t('ip'),
                    type: 'text',
                    key: 'ip',

                },
                {
                    title: this.$t('type'),
                    type: 'custom-html',
                    key: 'type',
                    modifier: (purchases) => {
                        return `<span>${this.$t(purchases)}</span>`;
                    }
                },
                {
                    title: this.$t('status'),
                    type: 'custom-html',
                    key: 'status',
                    isVisible: true,

                    modifier: (value) => {
                        if (value){
                            let className = 'warning';
                            if (value === 'success') className = `success`;
                            return `<span class="badge badge-sm badge-pill badge-${className}">${this.$t(value)}</span>`;
                        }
                    }
                },
                {
                    title: this.$t('version'),
                    type: 'text',
                    key: 'version',

                },
            ],
        }
    },
}