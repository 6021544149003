<template>
    <div class="row mx-0 justify-content-center">

        <div class="col-11 col-md-10 col-lg-9 col-xl-8 px-primary margin-top-30 margin-bottom-30">
            <div class="d-flex align-items-center justify-content-start mb-primary">
                <app-icon name="layers" class="text-secondary size-36"/>
                <div class="ml-3">
                    <p class="mb-0 font-size-90">
                        {{ `${$t('this_purchase_code_is_used')} ${usedCount} ${$t('times').toLowerCase()}` }}</p>
                </div>
            </div>
            <div v-for="(purchase, index) in data['purchases']"
                 class="d-flex align-items-center justify-content-start mb-primary">
                <div class="width-150 d-flex align-items-center">
                    <app-icon name="corner-down-right" class="text-info"/>
                    <p class="mb-0 text-info">
                        {{ index === 0 ? $t('last') : ordinal(data['purchases'].length - index) }}
                    </p>
                </div>
                <div class="ml-3 width-350">
                    <p class="mb-0">
                        <span class="text-muted">{{ `${$t('domain')}: ` }}</span>
                        <span class="font-size-90">{{ purchase.domain_name }}</span>
                    </p>
                    <p class="mb-0">
                        <span class="text-muted">{{ `${$t('ip')}: ` }}</span>
                        <span class="font-size-90 text-capitalize">
                          {{ purchase.ip ?  purchase.ip: '-' }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ordinal} from "../../../../helpers/TextHelper";

export default {
    name: "PurchasesExpandableView",
    props: {
        data: {},
        tableId: {}
    },
    data() {
        return {
            ordinal,
            actions: [
                {
                    title: this.$t('view'),
                    key: 'view',
                    icon: 'eye',
                },
                {
                    title: this.$t('unassigned_job'),
                    key: 'unassigned',
                    icon: 'x-circle',
                }
            ]
        }
    },
    computed: {
        usedCount() {
            return this.data['purchases'].length
        },
    },
}
</script>
