export const PurchaseSummaryMixin = {
    data() {
        return {
            tableColumns: [
                {
                    title: this.$t('purchase_key'),
                    type: 'text',
                    key: 'purchase_key',
                },
                {
                    title: this.$t('buyer'),
                    type: 'text',
                    key: 'buyer',
                },
                {
                    title: this.$t('status'),
                    type: 'custom-html',
                    key: 'purchases',
                    modifier: (purchases) => {
                        let ClassName = 'success', item = this.$t('ok');
                        if (purchases) {
                            if (_.uniqWith(purchases, _.isEqual).length > 1) {
                                ClassName = 'danger';
                                item = this.$t('risk');
                            }
                        }
                        return `<span class="badge badge-sm badge-pill badge-${ClassName}">${item}</span>`;
                    }
                },
                {
                    title: this.$t('purchase_key_used_count'),
                    type: 'text',
                    key: 'count',
                    isVisible: true,
                },
                {
                    title: this.$t('purchases'),
                    type: 'expandable-column',
                    key: 'purchases',
                    className: 'success',
                    componentName: 'purchases-expandable-view',
                    modifier: (value, rowData) => {
                        let returnObj = {},
                            purchase = rowData['purchases'].length;
                        returnObj.prefixData = purchase > 0 ? '' : '-';
                        returnObj.title = purchase > 1 ? this.$t('multi') : this.$t('single');
                        returnObj.className = purchase > 1 ? 'warning' : 'success';
                        returnObj.expandable = purchase > 1;
                        returnObj.visible = purchase > 0;
                        return returnObj;
                    }
                }
            ],
        }
    },
}