import * as actions from "../../../../config/ApiUrl";

export const ReleaseTableHelpers = {
    data() {
        return {
            tableColumns : [
                {
                    title: this.$t('version'),
                    type: 'text',
                    key: 'version',

                },
                {
                    title: this.$t('release_date'),
                    type: 'text',
                    key: 'release_date',

                },
                {
                    title: this.$t('status'),
                    type: 'custom-html',
                    key: 'status',
                    isVisible: true,
                    modifier: (value) => {
                        if (value) {
                            let ClassName = 'warning';

                            if (value === 'created') ClassName = `secondary`;
                            else if (value === 'published') ClassName = `success`;

                            return `<span class="badge badge-sm badge-pill badge-${ClassName}">${this.$t(value)}</span>`;
                        }
                    }
                },
                {
                    title: this.$t('zip_file'),
                    type: 'link',
                    key: 'zip_file_reference',
                    modifier: (value, rowData) => {
                        return `${actions.DOWNLOAD_ZIP}/${rowData.id}`;
                    },

                },
            ],
            actionObj : {
                title: this.$t('action'),
                type: 'action',
            }
        }
    },
}