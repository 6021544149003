import * as actions from "../../../../config/ApiUrl";
export const TableHelpers = {
    data() {
        return {
            tableColumns : [
                {
                    title: this.$t('app_name'),
                    type: 'link',
                    modifier: (value, rowData) => {
                        return `${actions.APP_DETAILS}/${rowData.id}`;
                    },
                    key: 'app_name',

                },
                {
                    title: this.$t('app_id'),
                    type: 'text',
                    key: 'app_id',
                },
                {
                    title: this.$t('code_canyon_item_id'),
                    type: 'custom-html',
                    key: 'code_canyon_item_id',
                    isVisible: true,
                    modifier: (value) => {
                        if (!value) {
                            let ClassName = 'warning';
                            return `<span class="badge badge-sm badge-pill badge-${ClassName}">${this.$t('not_set_yet')}</span>`;
                        }else{
                            return value;
                        }
                    },
                },
                {
                    title: this.$t('status'),
                    type: 'custom-html',
                    key: 'status',
                    isVisible: true,
                    modifier: (value) => {
                        if (value) {
                            let ClassName = 'warning';

                            if (value === 'created') ClassName = `secondary`;
                            else if (value === 'published') ClassName = `success`;

                            return `<span class="badge badge-sm badge-pill badge-${ClassName}">${this.$t(value)}</span>`;
                        }
                    }
                },
            ],
            actionObj : {
                title: this.$t('action'),
                type: 'action',
            }
        }
    },
}